body {
  margin: 0;
  background-color: #E5E5E5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: auto;
  height: auto;
}

.settings-page-tree {
  background-color: #E5E5E5;
}

/* при наведении */
.nav {
  --bs-nav-link-hover-color: #555555;
}

/* кнопка активной вкладки */
.nav-pills .nav-link.active {
  background-color: #cdcdcda5 !important; /* фон */
  color: #252525 !important; /* цвет текста */
  font-weight: 500; 
}

/* обычная кнопка вкладки */
.nav-link {
  color: #808080;
}

.nav-primary {
  font-size: 19px;
}

.nav-secondary {
  font-size: 12px;
}

.floating-alert {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1050;
  width: 350px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: opacity 1.2s;
}

.table-user {
  --bs-table-color: #000000;
  --bs-table-bg: #A2A3A4;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
}

.modal-content {
  background-color: #f5f5f5;
}

.page-item {
  --bs-pagination-bg: #E5E5E5;
  --bs-pagination-color: #808080;
  --bs-pagination-active-bg: #E5E5E5;
  --bs-pagination-active-color: #252525;
  --bs-pagination-active-border-color: #0d6dfd00;
  --bs-pagination-border-width: 0;
  --bs-pagination-hover-color: #555555;
  --bs-pagination-hover-bg: #E5E5E5;
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: #252525;
  --bs-pagination-focus-bg: #E5E5E5;
  --bs-pagination-focus-box-shadow: 0 0 0 0;
}

.page-item .active {
  font-weight: 500;
}

.modal-content {
  width: auto !important;
}

.image-limited {
  max-height: 90vh;
  max-width: 100%;
}

.bg-light-grey {
  background-color: #f5f5f5!important;
}

/* grey buttons */
.btn-grey {
  --bs-btn-color: #353535;
  --bs-btn-bg: #E5E5E5;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #303030;
}

.col-tiny-left {
  width: 2%;
}

.ant-statistic-title {
  color: inherit !important;
}

#root {
  margin-top: 50px; /* Same as header height */
}

.body-back-color {
  background-color: #E5E5E5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#language-toggler {
  min-width: auto;
  position: absolute;
}
